import * as React from "react";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import MorePlants from "../components/Home/MorePlants";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/UI/Button";

const NotFoundPage = () => {
  return (
    <Layout
      pageTitle="Home Page"
      description={
        "Flora Pod Sensors - Smart plant sensors that save your plants. These sensors are designed to optimize temperature, humidity, moisture, and light for all of your precious plants."
      }
    >
      <section className="flex pt-20 pb-5 bg-[#F3FFF9]  px-5 sm:px-6 md:max-lg:px-0 xl:px-40 md:px-8 mx-auto flex-col justify-center items-center">
        <h2
          data-sal="fade"
          data-sal-duration="100"
          data-sal-delay="10"
          data-sal-easing="ease"
          className="flex justify-center text-2xl font-avenir800  mb-5 md:text-4xl  md:flex  md:pt-10 md:pb-5"
        >
          Oops...
        </h2>
        <StaticImage className="h-100vh" src="../images/404.png" />
        <h4 className="text-2xl font-avenir800 pt-10 text-center">
          It looks like nothing was found at this location
        </h4>
        <Button
          handleClick={() => navigate("/")}
          label="Go Home"
          className="text-white px-16 py-2 m-5"
        />
      </section>
      <MorePlants />
    </Layout>
  );
};

export default NotFoundPage;
